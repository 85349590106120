<template>
  <div class="popup_wrap popup_wrap_pdf" style="width:1000px;">
    <button type="button" class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">Previous Issued Invoice</h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left col_3">
            <table class="tbl_list font_sm gridtype">
              <colgroup>
                <col width="100%">
              </colgroup>
              <thead>
                <tr>
                  <th>{{ $t('msg.REPORT_PDF.002') }}<!-- B/L No. --></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ parentInfo.blNo }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="float_left text_center col_7 pl20" style="max-height:500px;overflow-y:auto">
            <!-- <div>{{ pdf.currentPage }} / {{ pdf.pageCount }}</div> -->
            <vue-pdf-app
              style="height: 300px;"
              :pdf="pdf.pdfUrl"
              :config="pdfConfig.config"
              :id-config="pdfConfig.idConfig"
              theme="light"
            ></vue-pdf-app>
            <div class="paging print">
              <span class="num">
                <input type="number" :id="pdfConfig.idConfig.pageNumber" class="pageNumber" maxlength="2">
                <span :id="pdfConfig.idConfig.numPages" class="pageNumber">-</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" v-show="vaAcctBtnYn" href="#" @click.prevent="fnVaAcctCnclYn();">가상계좌취소</a>
        <a class="button blue lg" href="#" @click.prevent="fnDownlaod();">{{ $t('msg.REPORT_PDF.101') }}<!-- 다운 --></a>
        <a class="button blue lg" :id="pdfConfig.idConfig.print">{{ $t('msg.REPORT_PDF.102') }}<!-- 인쇄 --></a>
        <a class="button blue lg" href="#" @click.prevent="openSendEmailPopup();">{{ $t('msg.REPORT_PDF.103') }}<!-- 공유 --></a>
      </div>
    </div>

    <win-layer-pop class="pdf_send_email">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="closeSendEmailPopup"
        :parent-info="sendEmailParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commonPdf from '@/plugins/commonPdf'
import 'vue-pdf-app/dist/icons/main.css'
import CalcIssue from '@/api/rest/settle/calcIssue'

const REPORT_URL = process.env.VUE_APP_REPORT_URL
const SETTLE_URL = process.env.VUE_APP_SETTLE_URL

export default {
  name: 'DemInvoicePop',
  components: {
    vuePdfApp: () => import('vue-pdf-app'),
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    PdfSendEmailPop: () => import('@/pages/pdf/popup/PdfSendEmailPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      customComponent: null,
      sendEmailParam: {},
      items: [],
      userInfo: {},
      pdf: {
        pdfUrl: '',
        emailUrl: '',
        emailFiles: [],
        pg: 1,
        pageCount: 0,
        currentPage: 0
      },
      pdfConfig: {
        config: {},
        idConfig: {}
      },
      param: {
        demDetCatCd: '01',
        calExts: 'BMOU6210963_20211119_0_0_undefined',
        eiCatCd: 'O',
        blNo: 'PUSE020438',
        invIssNo: ''
      },
      vaAcctBtnYn: false,
      vaAcctBtnCd: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo: {
      deep: true,
      handler () {
       this.setItems()
      }
    }
  },
  created () {
    this.init()
    console.log('# auth info : ', this.auth)
    console.log('# auth memberDetail : ', this.memberDetail)
  },
  methods: {
    async init () {
      this.pdfConfig = commonPdf.getPdfConfig()

      // 로그인 여부 체크
      if (!this.auth.userId) {
        await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.REPORT_PDF.115') }) // 로그인을 확인해주세요.
        this.$emit('close')
        return
      }

      this.setItems()

      commonPdf.commonInit()
      this.fnVaAcctCnclBtn()
    },
    async setItems () {
      let req = JSON.parse(JSON.stringify(this.parentInfo))
      this.pdf = await commonPdf.setDemInvoice(this.parentInfo, this.memberDetail.bkgPlcCd)
    },
    fnDownlaod () {
      console.log('fnDownlaod')
      console.log(this.pdf.downloadUrl)
      commonPdf.download(this.pdf.downloadUrl, 'KMTC' + this.parentInfo.blNo + '.pdf')
    },
    openSendEmailPopup () {
      this.sendEmailParam = {
        emailUrl: this.pdf.emailUrl,
        emailFiles: this.pdf.emailFiles,
        type: 'AN'
      }
      this.customComponent = 'PdfSendEmailPop'
      commonPdf.layerOpen('.pdf_send_email')
    },
    closeSendEmailPopup () {
      this.customComponent = null
      commonPdf.layerClose('.pdf_send_email')
    },
    async fnVaAcctCnclYn () {
      let param = {
            blNo: this.parentInfo.blNo,
            invIssNo: this.parentInfo.invoice.invIssNo
        }
      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: '가상계좌를 취소하시겠습니까?', useConfirmBtn: true })) {
          return
      }
      await CalcIssue.cancelVirtualAccount(param).then(response => {
        this.vaAcctBtnYn = true
        this.$ekmtcCommon.asyncAlertMessage({ message: '정상적으로 취소되었습니다.' })
        this.$store.commit('settle/updateIsRefreshFreetimeReq', true)
        this.$emit('close')
       }).catch(e => {
         console.log(e)
      })
    },
    async fnVaAcctCnclBtn () {
      let param = {
        blNo: this.parentInfo.blNo,
        invIssNo: this.parentInfo.invoice.invIssNo
      }
      await CalcIssue.virtualAccountCnclBtn(param).then(response => {
          this.vaAcctBtnCd = response.data
          this.vaAcctBtnYn = this.$ekmtcCommon.isNotEmpty(response.data)
       }).catch(e => {
         console.log(e)
      })
    }
  }
}
</script>

<style>

body {
  min-width:100% !important;
  overflow: auto !important;
}

</style>
